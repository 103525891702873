.bigHeader {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    width: 100%;
    box-shadow: 0px 3px 10px #000000;
    border: 1px solid #707070;
    margin: 0 auto 30px;
    background: white;
}

.fixed {
    position: fixed;
    z-index: 100;
    margin: 0 -16px;
    width: 100vw;
    top: 48px;
}


.bigHeaderCell {
    flex-grow: 1;
    flex-basis: 0;
    padding: 15px 15px;
    position: relative;
}

.bigHeaderCell:not(:last-child)::after { 
    content: '';
    border-right: 1px solid #707070;
    height: 80%;
    display: block;
    position: absolute;
    top: 10%;
    right: 0;
}