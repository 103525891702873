.statusTable {
  overflow: auto;
  max-height: 75vh;
}

.statusTable > table > thead > tr > th {
  position: sticky;
  top: 0;
  background-color: white !important;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}
