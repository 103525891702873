:root {
  --color-white: white;
  --color-primary: #22A6EE;
  --color-primary-light: #3DC6F9;
  --color-primary-dark: #172DA6;
  --color-secondary: #1D232D;
  --color-positive: #016936;
  --color-negative: #DB5461;
  --color-orange: #F8A14B;
  --color-green: #3DA35D;
  --color-green-medium-sea: #77D193;
  --color-text: #6F6F6F;
  --color-blue: #347cb7;
  --color-red: #9a1428;
}

html,
body,
#root,
.app,
#root > div {
  height: 100vh;
  width: 100vw;
  background-color: var(--color-white);
  font-family: 'Open Sans',Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

@media (min-width: 992px) {
  html { font-size: 16px !important; }
  body { font-size: 16px !important; }
}

@media (max-width: 992px) {
  html { font-size: 16px !important; }
  body { font-size: 16px !important; }
}

@media (max-width: 768px) {
  html { font-size: 16px !important; }
  body { font-size: 16px !important; }
}

.body {
  color: var(--color-text);
}

.bg-primary {
  background-color: var(--color-primary);
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

.color-white {
  color: var(--color-white);
}

.color-positive {
  color: var(--color-positive);
}

.color-negative {
  color: var(--color-negative);
}

.color-orange {
  color: var(--color-orange);
}

.color-green {
  color: var(--color-green);
}

.color-blue {
  color: var(--color-blue);
}

.color-red {
  color: var(--color-red);
}

.pac-container {
  width: 350px;
}


.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}