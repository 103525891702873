.utilizationChartPicker {
  display: flex;
}

@media (min-width: 768px) {
  .utilizationChartPicker {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .utilizationChartPicker {
    flex-direction: row;
    justify-content: space-between;
  }
}
