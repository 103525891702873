/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span,
.CalendarDay__selected_span:active {
  background: var(--color-primary-light);
  border-color: var(--color-primary);
  color: white;
}

.CalendarDay__selected_span:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: white;
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected,
.CalendarDay__selected:active {
  background: var(--color-primary-light);
  border-color: var(--color-primary);
  color: white;
}

.CalendarDay__selected_start {
  background-color: var(--color-primary-dark);
  border-color: var(--color-primary-dark);
}

.CalendarDay__selected_end {
  background-color: var(--color-primary-dark);
  border-color: var(--color-primary-dark);
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: white;
}

/* Will edit when the second date (end date) in a range of dates is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: var(--color-primary-light);
  border-color: var(--color-primary);
  color: white;
}

.DayPicker_weekHeader {
  top: 54px;
}

.DateInput {
  width: inherit;
}

.DateInput_input__focused {
  border: none;
}

.DateInput_input {
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  font-size: inherit;
  border: none;
  padding-right: 0;
}

.SingleDatePickerInput__withBorder {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
}

.SingleDatePickerInput_calendarIcon {
  margin: 0;
}

.DateInput_fang {
  z-index: 4;
}

.SingleDatePicker_picker {
  z-index: 3;
}
